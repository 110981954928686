import React from 'react';
import NetworkContainer from '../containers/NetworkContainer';
import ToggleContainer from '../containers/ToggleContainer';
import SearchGraphContainer from '../containers/SearchGraphContainer';
import ButtonFeaturesContainer from '../containers/ButtonFeaturesContainer';
import { Nav, NavItem } from 'react-bootstrap';
import '../css/know-your-agency.css';
import { toggleFDWsOnly } from '../actions/actionTypes'; 
import { connect } from 'react-redux';



class Filter extends React.Component {
  constructor(props) {
    super();
    this.generateToggles = this.generateToggles.bind(this);
    // this.state = {
    //   fdwOnly: false, // Initialize fdwOnly to false
    // };
  }

  /**
  * Function that creates all of the toggles within the filter.
  * Iterates trough filter object, and creates a toggle for each
  * key.
  * @param {Object} filter -- object containing all filter categories and items
  * @param {function} updateFilter -- function that updates state on click
  * @returns {HTML} -- HTML of toggles
  * 
  */

  generateToggles = () => {
    let filter = ['Exact Address', 'Bounding Address', 'Telephone', 'Email', 'Fax'];
    let toggles = [];
    let i = 0;
    filter.forEach(function(toggle) {
      toggles.push(<ToggleContainer eventkey={i++}
                      name={toggle} key={i} 
                      />)
    })
    return (
      <div>
        {toggles}
      </div>
    )
  }
  handleCheckboxChange = () => {
    this.props.toggleFDWsOnly(); 
  };

  render() {
    return (
      <div>
        <div className="know-your-agency-filter">
          <Nav bsStyle="pills" stacked activeKey={1}>
            <NavItem disabled>Filter</NavItem>
          </Nav>
          {this.generateToggles()}
          <SearchGraphContainer placeholder="Search Displayed Agencies" class="know-your-agency-search" />
          <ButtonFeaturesContainer />
          <div className="toggle-fdw">
            <label>
            <input
            type="checkbox"
            id = "checkbox"
            checked={this.props.fdwOnly}
            onChange={this.handleCheckboxChange}
            />
              Show FDW Only
            </label>

          </div>
          <div className="key-container">
            <div className="key-container">
              <div>
                <span className="agency-dot" />
                <p className="key">   = Employment Agency</p>
              </div>
              <div>
                <span className="accredited-ph-agency-dot" />
                <p className="key">   = Philippines Accredited Employment Agency</p>
              </div>
              <div>
                <span className="accredited-ind-agency-dot" />
                <p className="key">   = Indonesia Accredited Employment Agency </p>
              </div>     
              <div>
                <span className="accredited-ph_ind-agency-dot" />
                <p className="key"> = Philippines & Indonesia Accredited Employment Agency </p>
              </div>                       
              <div>
                <span className="money-lender-dot" />
                <p className="key">   = Money Lender /
                <p className="FSP">Financial Service Provider</p>
              </p>
              </div>
            </div>
            <div className="key-container">
              Zoom with mouse / trackpad scroll or navigation buttons
            </div>
          </div>
        </div>
        <NetworkContainer />
      </div>
    );
  }
}


// export default Filter;
const mapStateToProps = (state) => {
  console.log('mapStateToProps called', state);
  return {
    fdwOnly: state.fdwOnly,
  };
};

const mapDispatchToProps = {
  toggleFDWsOnly, 
};

export default connect(mapStateToProps, mapDispatchToProps)(Filter);