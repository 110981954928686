/*
 * action types
 */
export const SELECT_PAGE = 'SELECT_PAGE';
export const FILTER_GRAPH = 'FILTER_GRAPH';
export const ZOOM_NODE = 'ZOOM_NODE';
export const SEARCH_AGENCY = 'SEARCH_AGENCY';
export const RESET = 'RESET';
export const TOGGLE_FDWS_ONLY = 'TOGGLE_FDWS_ONLY';

export const toggleFDWsOnly = () => {
    console.log('toggleFDWsOnly action dispatched');
    return {
      type: TOGGLE_FDWS_ONLY,
    };
  };