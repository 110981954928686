import initialState from './initialState';
import {FILTER_GRAPH, ZOOM_NODE, RESET,TOGGLE_FDWS_ONLY} from '../actions/actionTypes';
const helpers = require('../helpers.js');

export default function visualizationReducer(state = initialState.visualization, action) {
  switch (action.type) {
    case FILTER_GRAPH:
      let currentFilter = state.filter.slice();
      let index = currentFilter.indexOf(action.filter);
      if (index !== -1) {
        currentFilter.splice(index, 1);
      } else {
        currentFilter.push(action.filter);
      }
      let data = helpers.retrieveGraphData(currentFilter);
      return {zoom: "", filter: currentFilter, data, graph: helpers.formatNetworkData(data, state.fdwOnly), center: false, fdwOnly: state.fdwOnly}
    case ZOOM_NODE:
      return {...state, zoom: action._id, center: false};
    case RESET:
      if (state !== initialState.visualization) {
        return {...state, zoom: "", data: initialState.visualization.data, filter: initialState.visualization.filter, graph: helpers.formatNetworkData(helpers.retrieveGraphData(['Exact Address', 'Bounding Address', 'Telephone', 'Email', 'Fax']), state.fdwOnly)};
      } else {
        return state;
      }
    case TOGGLE_FDWS_ONLY:
      console.log('TOGGLE_FDWS_ONLY action received', state.fdwOnly); 
      let currentFdwOnly = !state.fdwOnly
      let data2 = helpers.retrieveGraphData(state.filter);
      return {zoom: "", filter: state.filter, data: data2, graph: helpers.formatNetworkData(data2, currentFdwOnly), center: false, fdwOnly: currentFdwOnly}



   default:
      return state;
  }
}
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// import initialState from './initialState';
// import { FILTER_GRAPH, ZOOM_NODE, RESET } from '../actions/actionTypes';
// const helpers = require('../helpers.js');

// export default function visualizationReducer(state = initialState.visualization, action) {
//   switch (action.type) {
//     case FILTER_GRAPH:
//       let currentFilter = state.filter.slice();
//       let index = currentFilter.indexOf(action.filter);
//       if (index !== -1) {
//         currentFilter.splice(index, 1);
//       } else {
//         currentFilter.push(action.filter);
//       }
//       let data = helpers.retrieveGraphData(currentFilter, state.isAccredited_ph, state.isAccredited_ind); // Pass the parameters here
//       return { zoom: "", filter: currentFilter, data, graph: helpers.formatNetworkData(data, state.isAccredited_ph, state.isAccredited_ind), center: false, isAccredited_ph: state.isAccredited_ph, isAccredited_ind: state.isAccredited_ind };

//     case ZOOM_NODE:
//       return { ...state, zoom: action._id, center: false };

//     case RESET:
//       if (state !== initialState.visualization) {
//         return {
//           ...state,
//           zoom: "",
//           data: initialState.visualization.data,
//           filter: initialState.visualization.filter,
//           graph: helpers.formatNetworkData(
//             helpers.retrieveGraphData(['Exact Address', 'Bounding Address', 'Telephone', 'Email', 'Fax']),
//             state.isAccredited_ph, // Pass the parameters here
//             state.isAccredited_ind  // Pass the parameters here
//           ),
//         };
//       } else {
//         return state;
//       }

//     default:
//       return state;
//   }
// }

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

